import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class MixPanelService {
  // Nombre que se sugería para el archivo: MixpanelService.service.ts
  // https://medium.com/@jeffreyyy/mixpanel-for-angular-e0c0d8c08d3a
  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(userToken: string): void {
    mixpanel.init('dc30d035a0e2db62bbe288d2c68a5a58');
    mixpanel.identify('ventas_landing');
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}, url: string): void {
    //if (url == "https://signup.contalink.com/") {
    if (url.includes("https://signup.contalink.com/")) {
      // Sólo si proviene de producción se registrará en MixPanel
      this.init(null);
      mixpanel.track(id, action);
    }
  }
}