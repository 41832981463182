import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostLogTransactionService {

  private url = null;
  private url_prod: string = 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/prod/cl-freemium-signup-backend/postlogtransaction';
  private url_qa: string = 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/qa/cl-freemium-signup-backend/postlogtransaction';
  private api_token: string = 'J5rfNHyuqg2r8L4OcdzGV5vESnhoWdGQ80jiAI5G';

  constructor(private http: HttpClient) {

  }

  public postContalinkLogTransaction(postLogTransaction: PostLogTransaction): Observable<any> {

    //let headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', this.api_token);
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'X-Api-Key': this.api_token
    })

    if (window.location.href.includes("//signup.contalink.com")) {
      this.url = this.url_prod;
    } else {
      this.url = this.url_qa;
    }
    
    return this.http.post<any>(this.url, 
    postLogTransaction,
    {
      headers: headers
    })
  }
}
export interface PostLogTransaction {
    action_key: string,
    browsertime: string,
    session_id: string,
    record_landing_id: number
}
