import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators,FormsModule } from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {MatStepper} from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { NotifierService } from "angular-notifier";
import { ActivatedRoute } from '@angular/router';
import { PostStripeService} from '../../services/post-stripe.service';
import { PostSlackStripeService} from '../../services/post-slack-stripe.service';
import { MixPanelService} from '../../services/mix-panel.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout_payment.component.html',
  styleUrls: ['./checkout_payment.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: false},
    },
  ]  
})

export class CheckoutPaymentComponent implements OnInit {
  fieldTextType: boolean = false;
  public firstFormGroup: FormGroup = new FormGroup({
  })
  public secondFormGroup: FormGroup = new FormGroup({
  })
  public signupForm: FormGroup = new FormGroup({
  })
  check_company: Promise<any> = null;
  checkCompany: Promise<any> = null;
  stepOneCompleted: boolean = true;
  stepTwoCompleted: boolean = false;
  stepThreeCompleted: boolean = false;
  titleLastStep: string = "Procesando";
  textLastStep: string = "Espera mientras se procesa su pago, por favor no cierre esta ventana.";
  textSubtitleLastStep: string = "";
  textUser: string = "";
  textPaymentAmount = "";
  url_path: string  = window.location.href;
  textLSMainMessage = "Espere mientras se procesa su pago, por favor no cierre esta ventana.";
  LSCobrado = false;
  LSError = false;
  textLSUserName = ""
  textLSOnBoardingAgent = ""
  textLSOnBoardingEmail = ""
  textLSOnBoardingPhone = ""
  isRetry: any = false;
  textRetrySecret: any = ""
  textLSStripeMessage = "";

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  @ViewChild('stepper')
  stepper: MatStepper;
 
  nextClick(): void {
    //this.stepper.linear = false;
    setTimeout(() => {
      this.stepper.selectedIndex = 4;
    });
    setTimeout(() => {
       this.stepper.linear = true;
    });
  }


  private readonly notifier: NotifierService;
  
  constructor(
    notifierService: NotifierService,
    private activatedRoute: ActivatedRoute,
    public PostStripe: PostStripeService,
    public PostSlackStripe: PostSlackStripeService,
    private mixpanelService: MixPanelService,
  ) {
    this.notifier = notifierService;
  }

  async ngOnInit() {
    let recurrence_msg = ""
    let testElements = Array.from(document.getElementsByClassName('mat-horizontal-stepper-header-container') as HTMLCollectionOf<HTMLElement>)
    testElements.forEach((element) => {
      element.style.pointerEvents = 'none';
    });    
    //this.nextClick(); // JUE1600
    this.activatedRoute.queryParams.subscribe(async params => {
      if (params['redirect_status'] == 'succeeded') {
        let data = {
          "uc_customer_stripe_id": params['setup_intent_client_secret'],
          "qa":false
        }
        let post_stripe: any = await this.PostStripe.postContalinkStripe(
            data
        ).toPromise()
        if (post_stripe.hasOwnProperty('stripe-retry')) {
          this.textRetrySecret = post_stripe['stripe-retry']['stripe_clientsecret_id'];
        }
        //if (post_stripe['stripe']['status'] == 1 && !post_stripe['error-stripe-retry']) {
        if ("stripe" in post_stripe) {
          //this.addMixPanelEvent('LandingPaymentSuccess', {status:'ok'}); //JUE1600
          if (post_stripe['stripe']['status'] == 1) {
            console.log("Stripe Payment Success", post_stripe );
            if (post_stripe['error']) {
              this.titleLastStep = "Pago Procesado";
              this.LSError = true
              this.textLSMainMessage = "Su pago ha sido procesado pero hemos tenido problemas para activar su cuenta. \nFavor de comunicarse con nuestro equipo vía info@contalink.com o por Whatsapp al +52 811 910 7569";
              
              let post_slack: any = await this.PostSlackStripe.postContalinkSlackStripe(
                post_stripe['request']
              ).toPromise()
            } else if (post_stripe['nonuser']) {
              this.titleLastStep = "Pago Procesado";
              this.LSError = true
              this.textLSMainMessage = "Su pago ha sido procesado. Un agente se comunicará con usted para activar sus productos. \nSi tiene cualquier duda favor de comunicarse con nuestro equipo vía info@contalink.com o por Whatsapp al +52 811 910 7569";
              let post_slack: any = await this.PostSlackStripe.postContalinkSlackStripe(
                post_stripe['request']
              ).toPromise()
            } else {
              this.LSCobrado = true
              this.titleLastStep = "Bienvenido a Contalink!"
              this.textLSOnBoardingAgent = post_stripe['deal']['onboarding_info']['onboardingagent']
              this.textLSOnBoardingEmail = post_stripe['deal']['onboarding_info']['email']
              this.textLSOnBoardingPhone = post_stripe['deal']['onboarding_info']['phone']
              this.textLSMainMessage = "¡Gracias, confirmamos su pago! Su cuenta ya esta activa en Contalink, hemos enviado a su correo electrónico sus datos de acceso.";
              this.textLSUserName = post_stripe['request']['email'];
              // Envíar Notificación stripe
              let post_slack: any = await this.PostSlackStripe.postContalinkSlackStripe(
                post_stripe['request']
              ).toPromise()
            }
          } else {
            console.log("Stripe Status <> 1");
            this.isRetry = true;
            this.LSError = true;
            //this.addMixPanelEvent('LandingPaymentError', {status:'error_stripe_status_not_zero_retry'});
            this.titleLastStep = "Algo ha salido mal con tu pago";
            if (post_stripe['stripe']['message']) {
              this.textLSMainMessage =  " ("+post_stripe['stripe']['message']+")";
            }
          }
        } else {
            console.log("Stripe string not in post_stripe JSON");
            this.titleLastStep = "Error.";
            this.LSError = true
            this.textLSMainMessage = "Ha habido un error al procesar su pago. \nFavor de comunicarse con nuestro equipo vía info@contalink.com o por Whatsapp al +52 811 910 7569";
            //this.addMixPanelEvent('LandingPaymentError', {status:'error'});
        }
      } else {
        this.titleLastStep = "Error.";
        this.LSError = true
        this.textLSMainMessage = "Ha habido un error al procesar su pago. \nFavor de comunicarse con nuestro equipo vía info@contalink.com o por Whatsapp al +52 811 910 7569";
        //this.addMixPanelEvent('LandingPaymentError', {status:'error'});
        console.log("Error de redirección.");
      }
    });
  }

  getMonthName(month) {
    switch(month) {
      case 0:
        return "Enero";
        break;
      case 1:
        return "Febrero";
        break;
      case 2:
        return "Marzo";
        break;                        
      case 3:
        return "Abril";
        break;
      case 4:
        return "Mayo";
        break;
      case 5:
        return "Junio";
        break;
      case 6:
        return "Julio";
        break;
      case 7:
        return "Agosto";
        break;
      case 8:
        return "Septiembre";
        break;
      case 9:
        return "Octubre";
        break;
      case 10:
        return "Noviembre";
        break;           
      case 11:
        return "Diciembre";
        break;        
      default:
        return "de mes";
    }    
  }
  addMixPanelEvent(action, params) {
    this.mixpanelService.track(
      action,
      params,
      this.url_path
    );
  }


}