import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GetOrderInfoService {

  private url = null;
  private url_prod: string = 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/prod/cl-freemium-signup-backend/orderinfo';
  private url_qa: string = 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/qa/cl-freemium-signup-backend/orderinfo';
  private api_token: string = 'J5rfNHyuqg2r8L4OcdzGV5vESnhoWdGQ80jiAI5G';

  constructor(private http: HttpClient) {

  }

  public getContalinkOrderInfo(getOrderInfo: GetOrderInfo): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', this.api_token);
    let params = new HttpParams().set("payment_order_id", getOrderInfo.payment_order_id);
    if (window.location.href.includes("//signup.contalink.com")) {
      this.url = this.url_prod;
    } else {
      this.url = this.url_qa;
    }    
    return this.http.get(this.url, {
      headers: headers,
      params: params
    });
  }
}
export interface GetOrderInfo {
  payment_order_id:string
}