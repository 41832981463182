import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpBackend } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class StripePmService {
    private url; // URL to web api

    constructor( private handler: HttpBackend) {}

    public attachStripe(customer_id: string, stripe_sk: string) {
        this.url = 'https://api.stripe.com/v1/customers/'+customer_id+'/payment_methods';
        let httpClient: HttpClient = new HttpClient(this.handler);
        const encodedData = btoa(stripe_sk);

        let headersRequest: HttpHeaders = new HttpHeaders();
        headersRequest=headersRequest.set('Content-Type', 'application/json');
        headersRequest=headersRequest.set('Authorization', 'Bearer '+stripe_sk);
        
        let options = {
          headers: headersRequest,
          params: new HttpParams().set("type", "card")
        };
    
        return httpClient.get<any>(this.url, options).pipe(
          map(res => {
            return res;
    
          }),
          catchError(res => {
    
            if (res.status === 500) {
              return of(res.statusText);
            } 
            
            return of(res.error.errors);
          })
    
        );
    
      }
}