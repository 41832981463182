import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostSpeiService {

  private url = null;
  private url_prod: string = 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/prod/cl-freemium-signup-backend/postspei';
  private url_qa: string = 'https://34ltgz05j4.execute-api.us-east-1.amazonaws.com/qa/cl-freemium-signup-backend/postspei';
  private api_token: string = 'J5rfNHyuqg2r8L4OcdzGV5vESnhoWdGQ80jiAI5G';

  constructor(private http: HttpClient) {

  }

  public postContalinkSpei(postSlack: PostSpei): Observable<any> {

    //let headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', this.api_token);
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'X-Api-Key': this.api_token
    })

    if (window.location.href.includes("//signup.contalink.com")) {
      this.url = this.url_prod;
    } else {
      this.url = this.url_qa;
    }
    
    return this.http.post<any>(this.url, 
    postSlack,
    {
      headers: headers
    })
  }
}
export interface PostSpei {
    name: string,
    email: string,
    phone: string,
    rfc: string,
    razon_social:string,
    rfc_facturacion: string,
    razon_social_facturacion: string,
    numero_usuarios: string,
    plan_id: string,
    total:string,
    discount:string,
    userpass:string,
    regimenName:string,
    regimen:string,
    postal_code:string,
    fuente:string,
    fuenteName:string,
    precio_deal:string,
    descuento_deal:string,
    utm_medium:string,
    utm_source:string,
    utm_campaign:string,
    first_utm:string,
    first_visit_date:string,
    referred:string,
    referred_name:string;
    referred_code:string;
    halfoff:number;
    business_partner_id:string;
}